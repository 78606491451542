import { Routes } from '@angular/router';
import { environment } from '@environment/environment';
import { forgotPasswordResolver } from '@feature/stand-alone/forgot-password/forgot-password.resolver';
import { IsGuestGuard } from '@guards/is-guest.guard';
import { TermsGuard } from '@guards/terms.guard';
import { LabelType } from '@service/breadcrumb/breadcrumb';

export const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'vergeten/:code',
                pathMatch: 'full',
                loadComponent: async () =>
                    (
                        await import(
                            '@feature/stand-alone/forgot-password/forgot-password.component'
                        )
                    ).ForgotPasswordComponent,
                resolve: {
                    user: forgotPasswordResolver,
                },
            },
            {
                path: 'email-veranderen/:code',
                pathMatch: 'full',
                loadChildren: async () =>
                    (
                        await import(
                            '@feature/stand-alone/change-email/change-email.module'
                        )
                    ).ChangeEmailModule,
            },
            {
                path: 'login',
                pathMatch: 'full',
                loadChildren: async () =>
                    (
                        await import(
                            '@feature/stand-alone/login-page/login-page.module'
                        )
                    ).LoginPageModule,
            },
            {
                path: 'vergeten',
                pathMatch: 'full',
                canActivate: [IsGuestGuard],
                loadComponent: async () =>
                    (
                        await import(
                            '@feature/stand-alone/forgot-password-page/forgot-password-page.component'
                        )
                    ).ForgotPasswordPageComponent,
            },
            {
                path: 'auth/discord',
                pathMatch: 'full',
                loadChildren: async () =>
                    (
                        await import(
                            '@feature/stand-alone/link-discord/link-discord.module'
                        )
                    ).LinkDiscordModule,
            },
            {
                path: 'chat-popup',
                loadComponent: async () =>
                    (
                        await import(
                            '@feature/chat-page-module/chat-page.component'
                        )
                    ).ChatPageComponent,
                data: {
                    breadcrumb: {
                        label: environment.baseTitle,
                    },
                },
            },
            {
                path: 'registreren',
                loadChildren: () =>
                    import('@feature/register-module/register.module').then(
                        (m) => m.RegisterModule,
                    ),
            },
            {
                path: 'go',
                loadChildren: async () =>
                    (
                        await import(
                            '@feature/affiliate-link-module/affiliate-link.module'
                        )
                    ).AffiliateLinkModule,
            },
            {
                path: '',
                canActivate: [TermsGuard],
                canActivateChild: [TermsGuard],
                loadComponent: async () =>
                    (
                        await import(
                            '@app/feature/base-template//base-template.component'
                        )
                    ).BaseTemplateComponent,
                loadChildren: async () =>
                    (await import('@app/routing/feature-routes')).featureRoutes,
                data: {
                    breadcrumb: {
                        label: {
                            param: 'Voetbalwedden',
                            type: LabelType.Text,
                        },
                    },
                },
            },
        ],
    },
];
